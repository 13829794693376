
import VerticalSVG from '@/components/svg/detail/VerticalSVG.vue'
import HorizontalSVG from '@/components/svg/detail/HorizontalSVG.vue'
import HorizontalShortSVG from '@/components/svg/detail/HorizontalShortSVG.vue'
import HeadingSM from '@/components/wrappers/HeadingSM.vue'
import BtnScroll from '@/components/parts/btn/BtnScroll.vue'
import Saw from '@/components/parts/Saw.vue'
export default {
  components: {
    HeadingSM,
    BtnScroll,
    VerticalSVG,
    HorizontalSVG,
    HorizontalShortSVG,
    Saw,
  },
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
    sectionIndex: {
      type: Number,
      default: 0,
    },
    // is used as error page
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tl: null,
      saw: false,
    }
  },
  mounted() {
    let heading = null
    this.tl = this.$gsap.timeline({
      defaults: { ease: 'custom.1' },
      scrollTrigger: {
        trigger: this.$el,
      },
      onComplete: () => {
        heading && heading.revert()
      },
    })
    this.tl.scrollTrigger.disable()

    if (this.$refs.heading) {
      heading = new this.$SplitText(this.$refs.heading, { type: 'chars, words, lines' })
      this.tl.from(heading.chars, {
        opacity: 0,
        stagger: {
          each: 0.1,
          from: 'start',
          grid: 'auto',
          ease: 'power2.inOut',
        },
      })
    }

    this.$refs.preheading && this.tl.from(this.$refs.preheading.$el, { opacity: 0, xPercent: -10, duration: 0.2 }, '>')
    if (this.$refs.scroll) {
      this.tl.from(this.$refs.scroll, { opacity: 0, yPercent: -10, duration: 1, ease: 'custom.3' }, '>')
    }

    this.$emit('sectionMounted')
  },
  beforeMount() {
    this.$nuxt.$on('go', this.anim)
  },
  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
  },
  methods: {
    anim() {
      this.tl.scrollTrigger.enable()
    },
    progress() {
      this.$gsap.to(window, { duration: 1, scrollTo: this.$el.clientHeight, ease: 'custom.1' })
    },
  },
}
